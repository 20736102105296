import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { CountdownConfig, CountdownGlobalConfig } from 'ngx-countdown';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { KeycloakAngularModule, KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { CookieService } from 'ngx-cookie-service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { filter, mergeMap, of } from 'rxjs';
import { NgChartsConfiguration, NgChartsModule } from 'ng2-charts';
import { TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { LottieModule } from 'ngx-lottie';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CodeInputModule } from 'angular-code-input';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AlertModule } from 'ngx-bootstrap/alert';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { JoyrideModule } from 'ngx-joyride';
import { NgxIntlTelInputModule } from '@capgo/ngx-intl-tel-input';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { environment } from '../environments/environment';
import { BASE_PATH } from '../../sdk/ecadence-sdk';
import { SharedModule } from './shared/shared.module';
import { UnderscoreToSpacePipe } from './shared/pipes/underscore-to-space.pipe';
import { AuthenticationService } from './services/authentication.service';
import { HttpInterceptorService } from './services/http-interceptor.service';
import { LoginPageComponent } from './pages/extranet/login-page/login-page.component';
import { LandingPageComponent } from './pages/extranet/landing-page/landing-page.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { DashboardLayoutComponent } from './layout/dashboard-layout/dashboard-layout.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AlertComponent } from './component/alert/alert.component';
import { ContactUsComponent } from './pages/extranet/contact-us/contact-us.component';
import { ServiceWorkerModule } from "@angular/service-worker";

/**
 *
 */
export function lottiePlayerFactory(): any {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web/build/player/lottie_svg');
}

/**
 *
 * @param keycloak
 * @param authenticationService
 * @param cadenceSchedulerService
 */
function initializeKeycloak(
  keycloak: KeycloakService,
  authenticationService: AuthenticationService
): () => Promise<boolean> {
  keycloak.keycloakEvents$
    .pipe(
      filter((value) => value.type === KeycloakEventType.OnAuthSuccess),
      mergeMap((value) => authenticationService.fetchUser())
    )
    .subscribe((v) => {});
  const orgOpen = window.open;
  window.open = function (
    url?: string,
    target?: string,
    features?: string,
    replace?: boolean
  ): WindowProxy {
    if (url && url.includes(environment.ecadenceReportUrl) && !url.includes('access_token')) {
      keycloak
        .getToken()
        .then((value) => {
          if (value) {
            const updatedUrl = `${url}${url.includes('?') ? '&' : '?'}access_token=${value}`;
            return orgOpen(updatedUrl, target, features);
          } else {
            return orgOpen(url, target, features);
          }
        })
        .catch((reason) => console.log(reason));
    } else {
      return orgOpen(url, target, features);
    }
    return null;
  };
  return (): Promise<boolean> => {
    if (authenticationService.isMobileApp) {
      return of(false).toPromise();
    }
    return keycloak?.init({
      config: environment.keycloakConfig,
      initOptions: {
        enableLogging: !environment.production,
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html'
      }
    });
  };
}

// AoT requires an exported function for factories
/**
 *
 * @param http
 */
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

/**
 *
 */
function countdownConfigFactory(): CountdownConfig {
  return { format: `mm:ss` };
}

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    DashboardLayoutComponent,
    ContactUsComponent,
    FooterComponent,
    HeaderComponent,
    LandingPageComponent,
    LoginPageComponent,
    AlertComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    LottieModule.forRoot({ player: lottiePlayerFactory }),
    ModalModule.forRoot(),
    ToastrModule.forRoot(),
    KeycloakAngularModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    CodeInputModule,
    NgbModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    TooltipModule,
    AlertModule,
    PdfViewerModule,
    PaginationModule,
    BsDatepickerModule,
    NgChartsModule,
    JoyrideModule.forRoot(),
    NgxIntlTelInputModule,
    NgOptimizedImage,
    PopoverModule,
    NgMultiSelectDropDownModule,
    ProgressbarModule.forRoot(),
    ImageCropperModule,
    TypeaheadModule,
    ServiceWorkerModule.register('./ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    CookieService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, AuthenticationService]
    },
    {
      provide: MatDialogRef,
      useValue: {}
    },
    {
      provide: 'VAPID_PUBLIC_KEY',
      useValue: environment.VAPID_PUBLIC_KEY
    },
    {
      provide: BASE_PATH,
      useValue: environment.apiBaseUrl
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    { provide: CountdownGlobalConfig, useFactory: countdownConfigFactory },
    { provide: NgChartsConfiguration, useValue: { generateColors: false } },
    UnderscoreToSpacePipe,
    TranslatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
