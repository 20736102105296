/**
 * eCadence API
 * The eCadence RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PortalUserPojo } from './portal-user-pojo';


export interface ScoreboardHeaderPojo { 
    scoreboardType?: ScoreboardHeaderPojo.ScoreboardTypeEnum;
    teamTitle?: string;
    teamWigTitle?: string;
    currentDateAndTime?: string;
    wigTitle?: string;
    wigEndDate?: string;
    numberOfDaysLeft?: number;
    wigScore?: number;
    winning?: boolean;
    totalCountOfLags?: number;
    totalCountOfCompletedLags?: number;
    scoreboardLastUpdatedAt?: string;
    scoreboardLastUpdatedBy?: PortalUserPojo;
    isSupervisor?: boolean;
    playerAssignmentRequestCount?: number;
    assignmentRequestCount?: number;
    countOfUncompletedCommitments?: number;
    countOfCompletedCommitments?: number;
    countOfUsersInLastCadence?: number;
    lastDecisionQuality?: number;
}
export namespace ScoreboardHeaderPojo {
    export type ScoreboardTypeEnum = 'DAILY' | 'WEEKLY' | 'MONTHLY' | 'YEARLY' | 'QUARTERLY';
    export const ScoreboardTypeEnum = {
        DAILY: 'DAILY' as ScoreboardTypeEnum,
        WEEKLY: 'WEEKLY' as ScoreboardTypeEnum,
        MONTHLY: 'MONTHLY' as ScoreboardTypeEnum,
        YEARLY: 'YEARLY' as ScoreboardTypeEnum,
        QUARTERLY: 'QUARTERLY' as ScoreboardTypeEnum
    };
}


