/**
 * eCadence API
 * The eCadence RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface HseMasterLogPojo { 
    id?: number;
    hseTypeConstant?: HseMasterLogPojo.HseTypeConstantEnum;
    score?: number;
}
export namespace HseMasterLogPojo {
    export type HseTypeConstantEnum = 'SICK' | 'CONFUSED' | 'EXECUTING' | 'MOTIVATED';
    export const HseTypeConstantEnum = {
        SICK: 'SICK' as HseTypeConstantEnum,
        CONFUSED: 'CONFUSED' as HseTypeConstantEnum,
        EXECUTING: 'EXECUTING' as HseTypeConstantEnum,
        MOTIVATED: 'MOTIVATED' as HseTypeConstantEnum
    };
}


