import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { AuthenticationService } from '../../../services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent implements OnInit {
  constructor(
    private authenticationService: AuthenticationService,
    private keycloak: KeycloakService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.keycloak
      .isLoggedIn()
      .then((v) => {
        if (v) {
          this.router.navigate(['/player/dashboard']);
        } else {
          this.login();
        }
      })
      .catch((v) => {
        this.login();
      });
  }

  async login() {
    this.authenticationService.logout(
      this.keycloak.getKeycloakInstance().createLoginUrl({
        redirectUri: window.location.origin + '/player/dashboard',
        prompt: 'login'
      })
    );
  }
}
