// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const commonEnv = {
  production: false,
  staging: false,
  local: false,
  keycloakConfig: {
    clientId: 'ecadence-frontend',
    realm: 'ecadence',
    url: 'https://ecadence.com/auth'
  },
  apiBaseUrl: '/api',
  sessionTimeout: 1440,
  ecadenceReportUrl: '/report-api',
  maxFileSizeInMb: 3,
  maxNumberOfDocuments: 3,
  siteUrl: 'http://localhost:7200/api',
  lagKpiDownloadLink: 'lag-kpi/[id]/supporting-documents/download',
  VAPID_PUBLIC_KEY:
    'BDtGtF77pytN2DcqfBOo9zuSRFwyDPImVnweG0KTL0A_vAhggJwQdz_j6p1BsPxMP0ejs4wRiElQLduUkKoOiBw'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
