/**
 * eCadence API
 * The eCadence RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CommitmentSearchFilter { 
    offset?: number;
    limit?: number;
    order?: CommitmentSearchFilter.OrderEnum;
    orderColumn?: string;
    wigId?: number;
    lagId?: number;
    lagMeasureId?: number;
    leadMeasureId?: number;
    playerName?: string;
    completionStatus?: CommitmentSearchFilter.CompletionStatusEnum;
    uom?: string;
    jiraId?: string;
    dueBefore?: string;
    dueAfter?: string;
    createdAfter?: string;
    createdBefore?: string;
}
export namespace CommitmentSearchFilter {
    export type OrderEnum = 'ASC' | 'DESC';
    export const OrderEnum = {
        ASC: 'ASC' as OrderEnum,
        DESC: 'DESC' as OrderEnum
    };
    export type CompletionStatusEnum = 'COMPLETED' | 'IN_REVIEW' | 'NOT_COMPLETED';
    export const CompletionStatusEnum = {
        COMPLETED: 'COMPLETED' as CompletionStatusEnum,
        IN_REVIEW: 'IN_REVIEW' as CompletionStatusEnum,
        NOT_COMPLETED: 'NOT_COMPLETED' as CompletionStatusEnum
    };
}


