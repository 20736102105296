import { NgModule } from '@angular/core';
import { ExtraOptions, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AccountMembershipPojo } from '../../sdk/ecadence-sdk';
import { LandingPageComponent } from './pages/extranet/landing-page/landing-page.component';
import { LoginPageComponent } from './pages/extranet/login-page/login-page.component';
import { HasMembershipGuard } from './shared/guards/has-membership.guard';
import { ContactUsComponent } from './pages/extranet/contact-us/contact-us.component';
import { ForbiddenPageComponent } from './shared/forbidden-page/forbidden-page.component';
import { PermissionGuard } from './shared/guards/permission-guard';
import PermissionsEnum = AccountMembershipPojo.PermissionsEnum;
import { LoggedInGuard } from "./shared/guards/logged-in.guard";

const routes: Routes = [
  {
    path: '404',
    component: ForbiddenPageComponent,
    pathMatch: 'full',
    data: {
      isExtranet: true
    }
  },
  {
    path: '',
    component: LoginPageComponent,
    pathMatch: 'full',
    data: {
      isExtranet: true
    }
  },
  {
    path: 'player',
    loadChildren: () =>
      import('./pages/intranet/intranet.module').then(
        (m) => m.IntranetModule
      ),
    data: {
      isExtranet: false,
      showNavBar: true,
      showFooter: false,
    },
    canActivate: [LoggedInGuard]
  },
];
const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerConfig)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
