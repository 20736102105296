import { commonEnv } from './environment.common';

const env: Partial<typeof commonEnv> = {
  production: true,
  keycloakConfig: {
    ...commonEnv.keycloakConfig,
    url: 'https://ecadence.ng/auth'
  },
  siteUrl: 'https://ecadence.ng/api',
};

export const environment = {
  ...commonEnv,
  ...env
};
