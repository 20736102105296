import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, NgOptimizedImage } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { CodeInputModule } from 'angular-code-input';
import { LottieModule } from 'ngx-lottie';
import { NgbPopover, NgbToast } from '@ng-bootstrap/ng-bootstrap';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { WebcamModule } from 'ngx-webcam';
import { TranslateModule } from '@ngx-translate/core';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { NgChartsModule } from 'ng2-charts';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { NgxIntlTelInputModule } from '@capgo/ngx-intl-tel-input';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { RouterLink } from '@angular/router';
import { lottiePlayerFactory } from '../app.module';
import { FilePreviewComponent } from './components/file-preview/file-preview.component';
import { FullPageLoaderComponent } from './components/loaders/full-page-loader/full-page-loader.component';
import { ShortestDatePipe } from './pipes/shortest-date.pipe';
import { UnderscoreToSpacePipe } from './pipes/underscore-to-space.pipe';
import { FileSvgComponent } from './components/svg/file-svg/file-svg.component';
import { AuthLinkDirective } from './directives/auth-link/auth-link.directive';
import { EnumToArrayPipe } from './pipes/enum-to-array.pipe';
import { NoDataSvComponent } from './components/svg/no-data-sv/no-data-sv.component';
import { ImageCropperComponent } from './components/image-cropper/image-cropper.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { FilterPreviewComponent } from './components/filter-preview/filter-preview.component';
import { CamelCaseToSpacePipe } from './pipes/camel-case-to-space.pipe';
import { FileUrlPipe } from './pipes/file-url.pipe';
import { ConfirmDialogComponent } from './components/dialogs/confirm-dialog/confirm-dialog.component';
import { DisapprovalDialogComponent } from './components/dialogs/disapproval-dialog/disapproval-dialog.component';
import { MinTwoDigitsPipePipe } from './pipes/min-two-digits-pipe.pipe';
import { AbbreviateNumberPipe } from './pipes/abbreviate-number.pipe';
import { ResetPasswordDialogComponent } from './components/dialogs/reset-password-dialog/reset-password-dialog.component';
import { AmountPipe } from './pipes/amount.pipe';
import { TotalRecordsComponent } from './components/total-records/total-records.component';
import { PageSizeComponent } from './components/page-size/page-size.component';
import { TotalAmountComponent } from './components/total-amount/total-amount.component';
import { NairaSignDirective } from './directives/naira-sign/naira-sign.directive';
import { AccountSwitcherComponent } from './components/account-switcher/account-switcher.component';
import { CharacterLimitComponent } from './components/character-limit/character-limit.component';
import { SuccessDialogComponent } from './components/success-dialog/success-dialog.component';
import { LoaderComponent } from './components/loaders/loader/loader.component';
import { FormErrorComponent } from './components/form-error/form-error.component';
import { NumberOnlyDirective } from './directives/number-only.directive';
import { EmComponent } from './components/em/em.component';
import { DatetimePipe } from './pipes/datetime.pipe';
import { TimeOnlyPipe } from './pipes/timeonly';
import { DateOnlyPipe } from './pipes/dateonly.pipe';
import { MaskPipe } from './pipes/mask.pipe';
import { DocumentAttachmentComponent } from './components/document-attachment/document-attachment.component';
import { GeneratedDateDialogComponent } from './components/dialogs/generated-date-dialog/generated-date-dialog.component';
import { NairaPipe } from './pipes/naira.pipe';
import { RedirectDialogComponent } from './components/dialogs/redirect-dialog/redirect-dialog.component';
import { FaceCaptureDialogComponent } from './components/face-capture-dialog/face-capture-dialog.component';
import { CustomFileInputComponent } from './components/custom-file-input/custom-file-input.component';
import { ErrorDialogComponent } from './components/dialogs/error-dialog/error-dialog.component';
import { AlertMessageComponent } from './components/alert-message/alert-message.component';
import { CustomSelectFieldComponent } from './components/custom-select-field/custom-select-field.component';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { PageSizeOptionComponent } from './components/page-size-option/page-size-option.component';
import { NumberStringPipe } from './pipes/number-string.pipe';
import { ButtonWithLoaderComponent } from './components/button-with-loader/button-with-loader.component';
import { LottieInFullTableComponent } from './components/lottie-in-full-table/lottie-in-full-table.component';
import { NavTemplateComponent } from './nav-template/nav-template.component';
import { CadenceViewComponent } from './components/cadence-view/cadence-view.component';
import { PlayerSummaryComponent } from './components/player-summary/player-summary.component';
import { PlayerLagmeasuresComponent } from './components/player-lagmeasures/player-lagmeasures.component';
import { PlayerLeadmeasuresComponent } from './components/player-leadmeasures/player-leadmeasures.component';
import { LeadMeasureEntryComponent } from './components/dashboard/lead-measure-entry/lead-measure-entry.component';
import { HelpCardComponent } from './components/dashboard/help-card/help-card.component';
import { LeadMeasureCommitmentsComponent } from './components/lead-measure-commitments/lead-measure-commitments.component';
import { CadenceModalComponent } from './components/cadence-modal/cadence-modal.component';
import { CadenceTimerComponent } from './components/cadence-timer/cadence-timer.component';
import { CustomTextAreaComponent } from './components/custom-text-area/custom-text-area.component';
import { UserWithoutWigCardComponent } from './components/user-without-wig-card/user-without-wig-card.component';
import { ForbiddenPageComponent } from './forbidden-page/forbidden-page.component';
import { RelativeTimePipe } from './pipes/relative-time.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { NotificationToastComponent } from './components/notification-toast/notification-toast.component';
import { FullFilePreviewComponent } from './components/full-file-preview/full-file-preview.component';
import { PlayerProfileCardComponent } from './components/player-profile-card/player-profile-card.component';
import { WigOverviewComponent } from "./components/dashboard/wig-overview/wig-overview.component";
import { LeadMeasureCardComponent } from "./components/leverage/lead-measure-card/lead-measure-card.component";
import { LeadMeasureTableComponent } from "./components/leverage/lead-measure-table/lead-measure-table.component";
import { PageLengthComponent } from "./components/page-length/page-length.component";
import {
  CommitmentPreviewCardComponent
} from "../pages/intranet/scoreboard-view/commitment-preview-card/commitment-preview-card.component";
import { RemoveNegativePipe } from "./pipes/remove-negative.pipe";
import { ObjectReloadPipe } from "./pipes/object-reload.pipe";
import { CountdownPipe } from "./pipes/count-down.pipe";
import { ConfirmLeaveComponent } from "./components/confirm-leave/confirm-leave.component";
import { EditKpiModalComponent } from "./components/edit-kpi-modal/edit-kpi-modal.component";
import { KpiDocumentModalComponent } from "./components/kpi-document-modal/kpi-document-modal.component";

@NgModule({
  declarations: [
    FullPageLoaderComponent,
    FileSvgComponent,
    BarChartComponent,
    CustomFileInputComponent,
    NoDataSvComponent,
    FilePreviewComponent,
    ButtonWithLoaderComponent,
    UnderscoreToSpacePipe,
    ShortestDatePipe,
    AuthLinkDirective,
    EnumToArrayPipe,
    AlertMessageComponent,
    FaceCaptureDialogComponent,
    ImageCropperComponent,
    FileUploadComponent,
    FilterPreviewComponent,
    CamelCaseToSpacePipe,
    FileUrlPipe,
    ConfirmDialogComponent,
    DisapprovalDialogComponent,
    MinTwoDigitsPipePipe,
    AbbreviateNumberPipe,
    AmountPipe,
    ResetPasswordDialogComponent,
    TotalRecordsComponent,
    PageSizeComponent,
    NairaSignDirective,
    TotalAmountComponent,
    AccountSwitcherComponent,
    CharacterLimitComponent,
    SuccessDialogComponent,
    LoaderComponent,
    FormErrorComponent,
    NumberOnlyDirective,
    EmComponent,
    DatetimePipe,
    TimeOnlyPipe,
    DateOnlyPipe,
    MaskPipe,
    RedirectDialogComponent,
    DocumentAttachmentComponent,
    GeneratedDateDialogComponent,
    NairaPipe,
    CustomFileInputComponent,
    ErrorDialogComponent,
    CustomSelectFieldComponent,
    PlayerSummaryComponent,
    PageSizeOptionComponent,
    NumberStringPipe,
    NumberStringPipe,
    LottieInFullTableComponent,
    LottieInFullTableComponent,
    NavTemplateComponent,
    CadenceViewComponent,
    PlayerSummaryComponent,
    CadenceViewComponent,
    PlayerLagmeasuresComponent,
    PlayerLeadmeasuresComponent,
    NavTemplateComponent,
    PlayerLagmeasuresComponent,
    PlayerLeadmeasuresComponent,
    LeadMeasureEntryComponent,
    HelpCardComponent,
    LeadMeasureCommitmentsComponent,
    CadenceModalComponent,
    CadenceTimerComponent,
    CustomTextAreaComponent,
    UserWithoutWigCardComponent,
    CustomTextAreaComponent,
    ForbiddenPageComponent,
    RelativeTimePipe,
    TruncatePipe,
    NotificationToastComponent,
    FullFilePreviewComponent,
    PlayerProfileCardComponent,
    WigOverviewComponent,
    LeadMeasureCardComponent,
    LeadMeasureTableComponent,
    PageLengthComponent,
    RemoveNegativePipe,
    ObjectReloadPipe,
    CountdownPipe,
    CommitmentPreviewCardComponent,
    ConfirmLeaveComponent,
    EditKpiModalComponent,
    KpiDocumentModalComponent
  ],
  providers: [DatePipe],
  imports: [
    CommonModule,
    ImageCropperModule,
    NgxDropzoneModule,
    FormsModule,
    WebcamModule,
    ReactiveFormsModule,
    CodeInputModule,
    LottieModule.forRoot({ player: lottiePlayerFactory }),
    NgbToast,
    AlertModule,
    TooltipModule,
    PdfViewerModule,
    BsDatepickerModule,
    TimepickerModule,
    TranslateModule,
    NgChartsModule,
    PaginationModule,
    CarouselModule,
    ProgressbarModule,
    PopoverModule,
    NgxIntlTelInputModule,
    NgMultiSelectDropDownModule,
    RouterLink,
    NgOptimizedImage,
    NgbPopover
  ],
  exports: [
    CommonModule,
    FullPageLoaderComponent,
    ReactiveFormsModule,
    AuthLinkDirective,
    ConfirmDialogComponent,
    FileSvgComponent,
    BarChartComponent,
    LeadMeasureEntryComponent,
    HelpCardComponent,
    LottieInFullTableComponent,
    FileUploadComponent,
    UnderscoreToSpacePipe,
    ShortestDatePipe,
    AlertMessageComponent,
    FilePreviewComponent,
    CharacterLimitComponent,
    EnumToArrayPipe,
    CustomFileInputComponent,
    FilterPreviewComponent,
    FileUrlPipe,
    MinTwoDigitsPipePipe,
    AbbreviateNumberPipe,
    AmountPipe,
    FaceCaptureDialogComponent,
    TotalRecordsComponent,
    PageSizeComponent,
    NairaSignDirective,
    TotalAmountComponent,
    NairaSignDirective,
    CamelCaseToSpacePipe,
    AccountSwitcherComponent,
    CodeInputModule,
    LoaderComponent,
    FormErrorComponent,
    NumberOnlyDirective,
    EmComponent,
    DatetimePipe,
    ButtonWithLoaderComponent,
    TimeOnlyPipe,
    DateOnlyPipe,
    SuccessDialogComponent,
    DocumentAttachmentComponent,
    MaskPipe,
    NairaPipe,
    CustomSelectFieldComponent,
    PageSizeOptionComponent,
    NumberStringPipe,
    NumberStringPipe,
    PlayerSummaryComponent,
    NavTemplateComponent,
    CadenceViewComponent,
    NavTemplateComponent,
    HelpCardComponent,
    CadenceTimerComponent,
    CustomTextAreaComponent,
    CustomTextAreaComponent,
    CadenceModalComponent,
    UserWithoutWigCardComponent,
    RelativeTimePipe,
    TruncatePipe,
    WigOverviewComponent,
    PageLengthComponent,
    RemoveNegativePipe,
    ObjectReloadPipe,
    CountdownPipe,
    CommitmentPreviewCardComponent,
    ConfirmLeaveComponent,
    EditKpiModalComponent,
    KpiDocumentModalComponent
  ]
})
//@ts-ignore
export class SharedModule {}
//@ts-ignore
